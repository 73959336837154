import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Header, Sidebar, BreadCrumb } from 'dan-components';
import MenuVariants from 'containers/Pages/menu';
import { useAuth0 } from 'config/react-auth0-spa';
import axios from 'axios';
import Loading from 'dan-components/Loading';
import Decoration from './Decoration';
import useStyles from './appStyles-jss';

const LeftSidebarLayout = (props) => {
  const { classes, cx } = useStyles();
  const {
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    pageLoaded,
    mode,
    gradient,
    deco,
    history,
    bgPosition,
    place,
    titleException,
    additionalData,
  } = props;

  const { accessTokenClaims, user, organizationInfo, getTokenSilently } =
    useAuth0();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState();
  const [organizationName, setOrganizationName] = useState(
    organizationInfo.name
  );
  const getVariantMenu = () => {
    if (!accessTokenClaims || !accessTokenClaims.roleId) {
      return [];
    }
    const { roleId } = accessTokenClaims;

    let menuVariant = [];
    switch (roleId) {
      case '1':
        menuVariant = MenuVariants.SUPER_ADMIN;
        break;
      case '3':
        menuVariant = MenuVariants.ORGANIZATION_ADMIN;
        break;
      case '5':
        menuVariant = MenuVariants.ARTIST;
        break;
      case '7':
        menuVariant = MenuVariants.DATA_MANAGER;
        break;
      case '9':
        menuVariant = MenuVariants.STATS_MANAGER;
        break;
      default:
        menuVariant = MenuVariants.DEFAULT;
    }
    return menuVariant;
  };

  const isResourceContentVisible = (childs) => {
    const visibleResourceChilds = [];
    childs.forEach((child) => {
      if (child.visibilityType === 'Visible') {
        visibleResourceChilds.push(child);
      }
    });
    if (visibleResourceChilds.length === 0) {
      return false;
    }
    return true;
  };

  const getResourceContent = async () => {
    try {
      const token = await getTokenSilently();

      const url = `${process.env.RESOURCES_URL}?folder=root`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const dataFromAPi = response.data;
      let menuVariant = getVariantMenu();
      menuVariant = menuVariant.filter(Boolean);
      if (
        !dataFromAPi ||
        (typeof dataFromAPi === 'object' &&
          Object.keys(dataFromAPi).length === 0)
      ) {
        setContent(menuVariant);
        setLoading(false);
        return;
      }

      const resourcesMenu = dataFromAPi.map((resource) => {
        const root = {
          key: 'resources',
          name: 'Resources',
          icon: 'ion-ios-book-outline',
          child: [],
        };
        const { roleId } = accessTokenClaims;
        const childs = resource.childs
          .filter((resourceFolderItem) => {
            if (resourceFolderItem.visibilityType === 'Hidden') {
              if (roleId === '1') {
                return true;
              }
              return false;
            }
            if (resourceFolderItem.visibilityType === 'HiddenInMenu') {
              return false;
            }
            if (resourceFolderItem.visibilityType === 'Visible') {
              if (roleId === '1') {
                return true;
              }
              if (
                resourceFolderItem.childs &&
                resourceFolderItem.childs.length === 0
              ) {
                return false;
              }
              if (
                resourceFolderItem.childs &&
                !isResourceContentVisible(resourceFolderItem.childs)
              ) {
                return false;
              }
              return true;
            }
            return false;
          })
          .map((resourceSubItem) => {
            const subItemMenu = {
              key: resourceSubItem.storageName,
              name: resourceSubItem.name,
              icon: 'ion-ios-folder-outline',
              link: `/resources/${resourceSubItem.storageName}`,
              linkParent: `/resources/${resourceSubItem.storageName}`,
              visibility: resourceSubItem.visibilityType,
            };
            if (resourceSubItem.childs) {
              const subitemchilds = resourceSubItem.childs.map(
                (resourceFolderItem) => ({
                  key: resourceFolderItem.storageName,
                  name: resourceFolderItem.name,
                  icon: 'ion-ios-box-outline',
                  link: `/resources/${resourceFolderItem.storageName}`,
                  parentLink: resourceSubItem.storageName,
                  visibility: resourceFolderItem.visibilityType,
                })
              );
              subItemMenu.child = subitemchilds;
              subItemMenu.expand = true;
            }
            return subItemMenu;
          });
        childs.sort((a, b) => a.name.localeCompare(b.name));
        root.child = childs;

        if (roleId === '1') {
          root.child.unshift({
            key: 'resources-management',
            name: 'Res management',
            link: '/resources-management',
            icon: 'ion-ios-home-outline',
          });
        }

        return root;
      });

      const filteredResourcesMenu = resourcesMenu.filter(Boolean);
      if (
        filteredResourcesMenu &&
        filteredResourcesMenu.length > 0 &&
        filteredResourcesMenu[0].child.length > 0
      ) {
        menuVariant.push(filteredResourcesMenu[0]);
      } else {
        console.warn('filteredResourcesMenu has no valid children, skipping');
      }

      const finalMenuVariant = menuVariant.filter(Boolean);

      setContent(finalMenuVariant);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const address = location.pathname;

  const getOrganizationName = async () => {
    setOrganizationName('');
    if (place && place.length > 0 && MenuVariants === '1') {
      const strippedAddress = address.substring('/organization/'.length);

      if (strippedAddress.endsWith('.csv')) {
        return;
      }

      try {
        const token = await getTokenSilently();

        const response = await axios.get(
          `${process.env.ORGANIZATION_URL}/${strippedAddress}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        await setOrganizationName(response.data.name);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('false', place);
    }
  };

  useEffect(() => {
    setOrganizationName(organizationInfo.name);
  }, [organizationInfo]);

  useEffect(() => {
    getResourceContent();
  }, []);

  useEffect(() => {
    getOrganizationName();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header
            toggleDrawerOpen={toggleDrawer}
            margin={sidebarOpen}
            gradient={gradient}
            position="left-sidebar"
            mode={mode}
            title={organizationName || ''}
            additionalData={additionalData}
            history={history}
            organizationInfo={organizationInfo}
          />

          <Sidebar
            open={sidebarOpen}
            toggleDrawerOpen={toggleDrawer}
            loadTransition={loadTransition}
            dataMenu={content}
            leftSidebar
            user={user}
          />
          <main
            className={cx(
              classes.content,
              !sidebarOpen ? classes.contentPaddingLeft : ''
            )}
            id="mainContent"
          >
            <Decoration
              mode={mode}
              gradient={gradient}
              decoration={deco}
              bgPosition={bgPosition}
              horizontalMenu={false}
            />
            <section className={cx(classes.mainWrap, classes.sidebarLayout)}>
              {titleException.indexOf(location.pathname) < 0 && (
                <div className={classes.pageTitle}>
                  {organizationName ? (
                    <>
                      <Typography
                        component="h4"
                        className={
                          (bgPosition === 'header'
                            ? classes.darkTitle
                            : classes.lightTitle) &&
                          (place === additionalData.name
                            ? classes.nonCapitalize
                            : classes.capitalize)
                        }
                        variant="h4"
                      >
                        {organizationName ||
                          (accessTokenClaims.roleId === '9'
                            ? 'Analytics'
                            : 'Dashboard')}
                      </Typography>
                      <BreadCrumb
                        separator=" / "
                        theme={bgPosition === 'header' ? 'dark' : 'light'}
                        location={location}
                        additionalData={additionalData}
                        organizationName={organizationName}
                      />
                    </>
                  ) : (
                    <>
                      <Typography
                        component="h4"
                        className={
                          (bgPosition === 'header'
                            ? classes.darkTitle
                            : classes.lightTitle) &&
                          (place === additionalData.name
                            ? classes.nonCapitalize
                            : classes.capitalize)
                        }
                        variant="h4"
                      >
                        {place ||
                          (accessTokenClaims.roleId === '9'
                            ? 'Analytics'
                            : 'Dashboard')}
                      </Typography>
                      <BreadCrumb
                        separator=" / "
                        theme={bgPosition === 'header' ? 'dark' : 'light'}
                        location={location}
                        additionalData={additionalData}
                      />
                    </>
                  )}
                </div>
              )}
              {!pageLoaded && (
                <img
                  src="images/spinner.gif"
                  alt="spinner"
                  className={classes.circularProgress}
                />
              )}
              <Fade in={pageLoaded} {...(pageLoaded ? { timeout: 700 } : {})}>
                <div className={!pageLoaded ? classes.hideApp : ''}>
                  {/* Application content will load here */}
                  {children}
                </div>
              </Fade>
            </section>
          </main>
        </>
      )}
    </>
  );
};

LeftSidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string,
  titleException: PropTypes.array.isRequired,
  additionalData: PropTypes.object.isRequired,
};

export default LeftSidebarLayout;
