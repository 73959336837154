/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  domain,
  client_id,
  redirect_uri,
  audience,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [accessTokenClaims, setAccessTokenClaims] = useState({});
  const [organizationInfo, setOrganizationInfo] = useState({});

  const getRoleName = (roleId) => {
    switch (roleId) {
      case '1':
        return 'Super admin';
      case '3':
        return 'Organization admin';
      case '5':
        return 'Artist';
      case '7':
        return 'Data manager';
      case '9':
        return 'Stats manager';
      default:
        throw new Error('Unrecognized roleId');
    }
  };

  const getOrganizationInfo = async (accessToken, roleId) => {
    if (roleId === '1') {
      return null;
    }
    try {
      setLoading(true);

      const url = process.env.ORGANIZATION_URL;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching organization info:', error);
      return null;
    }
  };

  useEffect(() => {
    console.log('Initializing Auth0', initOptions);

    const initAuth0Async = async () => {
      try {
        const auth0FromHook = await createAuth0Client({
          domain,
          clientId: client_id,
          authorizationParams: {
            redirect_uri,
            audience,
          },
        });

        setAuth0(auth0FromHook);

        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        if (window.location.search.includes('error=')) {
          await auth0FromHook.logout();
        }

        const isAuthenticatedResult = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticatedResult);

        if (isAuthenticatedResult) {
          const userFromHook = await auth0FromHook.getUser();
          setUser(userFromHook);
          const accessToken = await auth0FromHook.getTokenSilently();
          console.log('Access Token:', accessToken);
          const accessTokenDecoded = jwt_decode(accessToken);

          const accessTokenClaimsObject = {
            decodedAccessToken: accessTokenDecoded,
            roleId: accessTokenDecoded['https://matchhype.com/roleId'],
            roleName: getRoleName(
              accessTokenDecoded['https://matchhype.com/roleId']
            ),
            organizationId:
              accessTokenDecoded['https://matchhype.com/organizationId'],
          };

          setAccessTokenClaims(accessTokenClaimsObject);
          const organizationInfoFromAPI = await getOrganizationInfo(
            accessToken,
            accessTokenDecoded['https://matchhype.com/roleId']
          );
          if (organizationInfoFromAPI !== null) {
            organizationInfoFromAPI.organizationId =
              accessTokenDecoded['https://matchhype.com/organizationId'];
            setOrganizationInfo(organizationInfoFromAPI);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error initializing Auth0:', error);
        setLoading(false);
      }
    };

    initAuth0Async();
  }, []);

  // const getNewTokenForOrganization = async (organizationData) => {
  //   const { organizationId } = organizationData;
  //   if (!auth0Client) {
  //     console.error('Auth0 client is not initialized');
  //     return;
  //   }

  //   const getToken = async () => {
  //     return auth0Client.getTokenSilently({
  //       authorizationParams: {
  //         audience,
  //         scope: 'openid profile email',
  //         custom_param: organizationId,
  //       },
  //       cacheMode: 'off',
  //     });
  //   };

  //   try {
  //     let token;
  //     try {
  //       token = await getToken();
  //     } catch (error) {
  //       if (
  //         error.error === 'login_required' ||
  //         error.error === 'consent_required'
  //       ) {
  //         await auth0Client.loginWithRedirect({
  //           authorizationParams: {
  //             audience,
  //             scope: 'openid profile email',
  //             custom_param: organizationId,
  //             redirect_uri: `${window.location.origin}/`,
  //           },
  //         });
  //         return;
  //       }
  //       throw error;
  //     }

  //     console.log('Token:', token);

  //     const decodedToken = jwt_decode(token);

  //     const accessTokenClaimsObject = {
  //       decodedAccessToken: decodedToken,
  //       roleId: decodedToken['https://matchhype.com/roleId'],
  //       roleName: getRoleName(decodedToken['https://matchhype.com/roleId']),
  //       organizationId: decodedToken['https://matchhype.com/organizationId'],
  //     };

  //     setAccessTokenClaims(accessTokenClaimsObject);

  //     const organizationInfoFromAPI = await getOrganizationInfo(token);
  //     if (organizationInfoFromAPI) {
  //       organizationInfoFromAPI.organizationId =
  //         decodedToken['https://matchhype.com/organizationId'];
  //       setOrganizationInfo(organizationInfoFromAPI);
  //     }

  //     const updatedUser = await auth0Client.getUser();
  //     setUser(updatedUser);
  //     setLoading(false);
  //   } catch (error) {
  //     if (
  //       error.error === 'login_required' ||
  //       error.error === 'consent_required'
  //     ) {
  //       console.error(
  //         'Silent token request failed, user interaction required:',
  //         error
  //       );
  //     } else {
  //       console.error('Error getting new token for organization:', error);
  //     }
  //     setLoading(false);
  //   }
  // };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const userFromGet = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(userFromGet);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        organizationInfo,
        accessTokenClaims,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
