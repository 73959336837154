import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import campaignImages from 'assets/images/campaigns/files';
import CampaignSettingsDialog from 'containers/UiElements/Campaigns/CampaignSettingsDialog';
import useStyles from './CampaignFlowHeader-jss';

const CampaignFlowHeader = (props) => {
  const { campaign, accessTokenClaims, getCampaignDataRequest } = props;
  const { roleId } = accessTokenClaims;
  const { classes } = useStyles();
  return (
    <div className={classes.descBlock} style={{ marginBottom: '16px' }}>
      <img
        src={
          campaign.thumbnail !== 'about:blank'
            ? campaign.thumbnail
            : campaignImages.emptyCampaignThumbnail
        }
        style={{ maxWidth: '150px', maxHeight: '150px' }}
        alt={campaign.type}
      />
      <div className={classes.titleText}>
        <Typography variant="h6" component="h2" className={classes.title}>
          Mode
          {': '}
          {campaign.type}
        </Typography>
        {roleId === '3' && (
          <CampaignSettingsDialog
            campaign={campaign}
            getCampaignDataRequest={getCampaignDataRequest}
          />
        )}
      </div>
    </div>
  );
};

CampaignFlowHeader.propTypes = {
  campaign: PropTypes.object,
  accessTokenClaims: PropTypes.object.isRequired,
};

CampaignFlowHeader.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  campaign: {
    thumbnail: campaignImages.emptyCampaignThumbnail,
    type: 'Manual mode',
  },
};

export default CampaignFlowHeader;
